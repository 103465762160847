<template>
  <div>
    <div class="footer">
      <div class="footer-top">
        <div class="container">
          <div class="footer-menu">
            <template v-for="(menu, index) in footerMenu">
              <div class="footer-item">
                <template v-if="menu.title && menu.desktop_show">
                  <span class="name-item name-item-desktop">{{ menu.title }}</span>
                </template>
                <template v-if="menu.title && menu.mobile_show">
                  <span class="name-item name-item-mobile">{{ menu.title }}</span>
                </template>

                <site-link v-for="submenu in menu.footer_submenu" v-if="submenu.desktop_show"
                           :key="submenu.id + '-desktop'" :link-item="submenu" :visible-class="'link-desktop'" />
                <site-link v-for="submenu in menu.footer_submenu" v-if="submenu.mobile_show"
                           :key="submenu.id + '-mobile'" :link-item="submenu" :visible-class="'link-mobile'" />


                <template v-if="rootCategory && menu.show_categories && menu.desktop_show">
                  <template v-for="sub1 in rootCategory.subcategories">


                    <span class="catalog-link-desktop" v-if="localePath(`/${rootCategory.slug}/${sub1.slug}/`) === $route.path">
                    <template v-for="translation in sub1.category_translations" v-if="translation.language.slug ===
                    $i18n.locale">
                      {{ translation.name }}
                    </template>
                  </span>

                  <nuxt-link v-else
                             :key="sub1.id + '-desktop'" class="catalog-link-desktop"
                             :to="localePath(`/${rootCategory.slug}/${sub1.slug}/`)"
                  >
                    <template v-for="translation in sub1.category_translations" v-if="translation.language.slug ===
                    $i18n.locale">
                      {{ translation.name }}
                    </template>

                  </nuxt-link>


                    </template>
                </template>

                <template v-if="rootCategory && menu.show_categories && menu.mobile_show">
                  <nuxt-link v-for="sub1 in rootCategory.subcategories"
                             v-if="$route.path !== localePath(`/${rootCategory.slug}/${sub1.slug}/`)"
                             :key="sub1.id + '-mobile'" class="catalog-link-mobile"
                             :to="localePath(`/${rootCategory.slug}/${sub1.slug}/`)"
                  >
                    <template v-for="translation in sub1.category_translations" v-if="translation.language.slug ===
                    $i18n.locale">
                      {{ translation.name }}
                    </template>
                  </nuxt-link>

                  <span class="catalog-link-mobile" v-else>
                    <template v-for="translation in sub1.category_translations" v-if="translation.language.slug ===
                    $i18n.locale">
                      {{ translation.name }}
                    </template>
                  </span>
                </template>

                <template v-if="footerText.length && index === footerMenu.length - 1">
                  <div v-html="footerText[0].text" class="footer-address"></div>
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="container">
          <div class="footer-info">
            <i class="svg-icon wetz-focus icon-brand"></i>
            <template v-if="footerText.length">
              <div class="copyright" v-html="footerText[0].copyright"></div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Footer, FooterText} from '@/models/menu';
import {Category} from '@/models/category';

import SiteLink from '@/components/site-link';

export default {
  components: {
    SiteLink,
  },
  data() {
    return {
      footerMenu: [],
      footerText: [],
      categories: [],
    }
  },
  watch: {
    '$i18n.locale': {
      handler(value) {
        Footer.config({count_loading: true}).get().then((response) => this.footerMenu = response);
        FooterText.config({count_loading: true}).get().then((response) => this.footerText = response);
        Category.config({count_loading: true}).get().then((response) => this.categories = response);
      },
    },
  },
  computed: {
    rootCategory() {
      return this.categories[0];
    },
  },
  async fetch() {
    [this.footerMenu, this.footerText, this.categories] = await Promise.all([
      Footer.config({count_loading: true}).get(),
      FooterText.config({count_loading: true}).get(),
      Category.config({count_loading: true}).get(),
    ]);
  },
  methods: {
    getCategoryLocaleData(category) {
      return category.category_translations?.find(x => x.language.slug === this.$i18n.locale) || {};
    },
  },
}
</script>
